.loading-center {
  top: 50%;
  left: 50%;
  position: absolute;
}

.progress-center {
  left: 40%;
  position: absolute;
}

.container-center {
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.truncate {
  width: 280px;
  font-size: 1rem !important;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Course Banner */
.course-img-wrapper {
  display: inline-block;
  width: 100%;
  height: 100%;
}

.course-thumbnail-wrapper div:first-child {
  min-height: 100% !important;
}

.course-thumbnail-wrapper div,
.course-banner-wrapper div {
  border: none;
  border-radius: inherit;
}

.course-thumbnail-wrapper {
  border: 1px solid var(--bs-card-border-color);
  /* background: var(--white); */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  border-radius: 8px;
  position: absolute;
  top: 100px;
  left: 50px;

}

.course-thumbnail-info {
  top: 300px;
  left: 45px;
}

.course-banner-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--bs-card-border-color);
  width: 100%;
  height: 250px;
  border-radius: 8px;
  /* margin-bottom: 50px; */
}

.course-banner-img {
  display: block;
  width: 100%;
  height: 100%;
  /* aspect-ratio: 1/1; */
  border: none;
  border-radius: inherit;
  object-fit: cover;
  object-position: center;
}

.course-thumbnail-edit {
  position: absolute;
  background: white;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50% !important;
  top: -12px;
  right: -12px;
  cursor: pointer;
  opacity: 0.8;
}

.course-banner-edit {
  position: absolute;
  background: white;
  display: flex;
  gap: 4px;
  padding: 8px;
  align-items: center;
  justify-content: center;
  bottom: 80px;
  right: 12px;
  cursor: pointer;
  opacity: 0.8;
  border-radius: 4px !important;
}

.organization-logo {
  max-width: 150px;
  max-height: 40px;
  background: white;
  border-radius: 10px;
}

/* .MuiTypography-h6 {
	font-size: 17px !important;
	color: #32325d !important;
	font-family: 'Open Sans' !important;
	font-weight: 600 !important;
} */

.drop-imgs-input {
  /* display: none; */
}

.drop-imgs {
  position: relative;
  margin-top: 12px;
  width: 70px;
  height: 70px;
  /* background-color:aqua; */
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.drop-imgs img {
  width: 70px;
  height: 70px;
  border-radius: 8px;
}

.drop-img-remove {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background: rgb(241, 241, 241);
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.drop-img-remove>span {
  cursor: pointer;
}

.desc {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.add_course_form_file_input .DropZone {
  position: absolute;
  width: 90%;
  opacity: 0;
}

/*  */
/*  */
.loading-center {
  top: 50%;
  left: 50%;
  position: absolute;
}

.main-content {
  /* background: #f5f7f8; */
}

.user-content {
  flex: 1;
}

.progress-center {
  left: 40%;
  position: absolute;
}

.container-center {
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.truncate {
  width: 260px;
  font-size: 1rem !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.color-primary {
  color: var(--primary);
}

.organization-logo {
  max-width: 150px;
  max-height: 40px;
  background: white;
  border-radius: 10px;
}

.btn:hover {
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  transform: none !important;
}

.org-primary-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;
  background: #0a84ff;
  border: 1px solid #0a84ff;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  color: #ffffff;
}

.lab-card {
  min-height: 184px;
}

.crop-container {
  position: relative;
  /* height: 200px; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
  z-index: 999999;
}

.controls {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  height: 80px;
  display: flex;
  margin-bottom: -20px;
  align-items: center;
}

.slider {
  padding: 22px 0px;
}

.buttons {
  margin-top: 35px;
}

.MuiTypography-h6 {
  font-size: 17px !important;
  color: #32325d !important;
  font-family: "Open Sans" !important;
  font-weight: 600 !important;
}

.category-description {
  font-size: 0.9rem;
}

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1320px;
  }
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 0;
  --bs-card-border-color: rgba(0, 0, 0, 0.125);
  --bs-card-border-radius: 1rem;
  --bs-card-box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  --bs-card-inner-border-radius: 1rem;
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: #fff;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.navbar-vertical .navbar-collapse:before {
  margin: 0;
}

.navbar-brand-img {
  max-height: fit-content !important;
}

.navbar-vertical.navbar-expand-md .navbar-brand {
  padding: 0;
  margin: 20px 0;
}

.sidebar-brand-img {
  height: 80px;
  width: 180px;
  object-fit: contain;
}

.react-tabs__tab--selected {
  background: #4099ff !important;
  border-radius: 5px !important;
}

.react-tabs__tab--selected span {
  color: white !important;
}

.slick-slide div {
  margin: 0 6px;
  /* padding: 6px; */
  /* background: transparent; */
}

.slick-prev {
  left: 3% !important;
  z-index: 1;
}

.slick-next {
  right: 3% !important;
  z-index: 1;
}

.round-header {
  border-radius: 1rem;
  margin: 10px;
}

.tr-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 21px;
  max-height: 45px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.tr-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 21px;
  max-height: 85px;
  height: 85px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.tbl-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 21px;
  max-height: 100px;
  height: 100px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.course-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  font-weight: 600;
  font-size: 18px;
  color: #344767;
  line-height: 21px;
  max-height: 40px;
  height: 40px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.course-link {
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #636363;
}

.course-link a {
  color: #4099ff;
}

.pack-card {
  min-height: 180px;
}

.homepage-table {
  /* min-height: 355px; */
  /* height: 355px; */

  width: 100%;
  height: 100%;
}

.homepage-table-wrapper {
  /* max-height: 350px;
      overflow-y: auto;
      overflow-x: hidden; */
}

.responsive {
  display: block;
  width: 100%;
  height: 300px;
  overflow-x: auto;
}

/* .homePage-table{
    height: 300px;
  } */
/* .homePage-th{
    height: 70px !important;
  } */

.no-top-i {
  top: 0 !important;
}

.course-iframe {
  min-height: 500px !important;
}

.course-content-card {
  min-height: 500px !important;
}

.states-card-title {
  font-size: 0.8125rem;
  font-weight: 600;
  line-height: 1.5;
}

.states-card-sub-title {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  color: #32325d;
}

.card-profile-img-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cursor-pointer,
.paly-pause-btn {
  cursor: pointer;
}

.play-icon-size {
  width: 20px;
}

.card-profile-img {
  height: 150px;
  width: 150px;
}

.card-profile-name {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.5;
  color: #32325d;
  text-transform: capitalize;
}

.input-sm {
  height: 30px;
  padding: 5px 10px;
  border-radius: 3px;
}

.course-video-wrapper video {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  border-radius: 1rem;
  object-fit: unset;
}

.course-video-wrapper div:first-child {
  display: contents;
}

.course-video-wrapper iframe {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  border-radius: 1rem;
  object-fit: unset;
}

.user-brand-logo {
  height: 35px;
  /* width: 100%; */
}

.navbar-collapse.nav-show {
  animation: "show-navbar-collapse 0.2s ease forwards";
}

.table-dropdown-action {
  position: absolute !important;
  will-change: transform !important;
  top: 0px !important;
  left: -150px !important;
  transform: translate3d(-0px, -0px, 0px) !important;
}

.course-img {
  display: contents;
  width: 100%;
  position: relative;
}

.course-img img {
  height: 232px;
  width: 100%;
  border-radius: 12px 12px 0px 0px;
}

.course-review {
  display: flex;
  align-items: center;
  gap: 4px;
}

.star-fill {
  color: #ffc200;
}

.star-fill-none {
  color: #666666;
}

.course-details {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 0;
  padding: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #8392ab;
}

.course-details li {
  list-style-type: none;
  width: auto;
}

.course-enrolled {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #8392ab;
}

.course-price {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

.course-complete-wrapper {
  display: block;
  position: absolute;
  top: 13px;
  right: 16px;
  height: 50px;
  width: 50px;
}

.course-complete-wrapper-list {
  display: block;
  position: relative;
  height: 50px;
  width: 50px;
}

.course-search-type {
  background-color: #dfdfdf;
  border-radius: 12px 0px 0px 12px;
  min-width: 200px !important;
}

.course-search-input {
  padding-left: 20px !important;
  padding-right: 20px !important;
  min-width: 500px !important;
}

.input-grp {
  border-radius: 12px !important;
}

.course-view-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.course-view-wrapper i {
  border: 1px solid #dfdfdf;
  background: #ffffff;
  color: #8392ab;
  border-radius: 4px;
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
}

.course-view-wrapper i:hover {
  box-shadow: 0 0 20px #ccc;
}

.course-sorting-wrapper {
  width: 250px;
}

.course-list-img img {
  height: 74px;
  width: 74px;
  border-radius: 12px;
}

/*  */
.list-course-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  font-weight: 600;
  font-size: 18px;
  color: #344767;
  line-height: 21px;
  max-width: 310px;
  width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.course-table-view {
  border-collapse: separate;
  border-spacing: 0 2em;
  overflow-x: auto;
}

.course-table-view tr {
  background-color: #ffffff;
  border-radius: 16px;
}

.list-course-txt {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #8392ab;
}

.list-course-txt-primary {
  font-weight: 700;
  font-size: 24px;
  line-height: 21px;
  color: var(--primary);
}

.course-table-view tr td {
  padding: 20px;
}

.course-table-view tr td:first-child {
  border-radius: 16px 0 0 16px;
}

.course-table-view tr td:last-child {
  border-radius: 0 16px 16px 0;
}

.list-course-link a {
  color: #8b8f92;
  cursor: pointer;
}

/* collaps */
.collaps-main-arrow i {
  color: var(--primary);
  font-size: 24px;
}

.accordion-header-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #1c1c1c;
}

.course-item-list-wrapper {
  max-height: 85vh;
  overflow-y: auto;
}

.course-item-list:not(:last-child) {
  border-bottom: 1px solid #f0f0f0;
  border-radius: 0 !important;
}

.course-download-btn {
  background: var(--primary);
  border-radius: 20px !important;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 21px;
  line-height: 29px;
  gap: 16px;
  height: 70px;
  width: 100%;
  margin: 24px 0;
}

.course-download-btn:hover {
  background: var(--primary);
}

.course-panel-title-wrapper i {
  color: var(--primary);
}

.course-panel-title {
  font-weight: 600;
  font-size: 21px;
  line-height: 29px;
  color: #1c1c1c;
}

/* Wrapper */
.course-tabs-wrapper {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dfdfdf;
}

.course-tab-title .nav-link {
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #676767;
  border-bottom: 5px solid transparent;
  cursor: pointer;
  padding: 12px;
}

.course-tab-title .nav-link:hover {
  border-bottom: 5px solid transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border: 1px solid transparent;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border: 1px solid transparent;
}

.course-decription {
  font-weight: 400;
  font-size: 22.2623px;
  line-height: 165.3%;
  color: #676767;
}

.form-content-wrapper-main {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/*  */
.org-layout-header {
  height: 250px;
}

/*  */

.org-profile-logo {
  width: 300px;
  height: 300px;
  border-radius: 1rem;
  display: flex;
}

.org-profile-logo img {
  object-fit: contain;
}

.org-name-title {
  font-weight: 600;
  font-size: 30px;
  line-height: 33px;
  letter-spacing: -0.8px;
  color: #344767;
}

/*  */
.content_card {
  background-color: white;
  width: 100%;
}

.course-details-wrapper {
  max-height: 90vh;
  overflow-y: auto;
}

.course-details-wrapper::-webkit-scrollbar {
  width: 8px;
}

.course-details-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.course-details-wrapper::-webkit-scrollbar-thumb {
  background: #888;
}

.course-details-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.is-sticky {
  position: fixed;
  top: 10px;
  right: 12px;
  z-index: 999;
  /* margin:; */
  /* animation: 500ms ease-in-out 0s normal none 1 running fadeInDown; */
}

/*  */
.course-info-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.course-info-wrapper div {
  /* width: 20%; */
  position: relative;
}

.course-info-name {
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  color: #000000;
}

.course-info-org-main-name {
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: #000000;
}

.course-info-org-name {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #b0b0b0;
}

.course-info-other-titles {
  font-weight: 400;
  font-size: 17.5945px;
  line-height: 150%;
  color: #676767;
}

.tooltip {
  width: max-content;
}

.tooltip>.tooltip-inner {
  background-color: rgba(0, 0, 0, 0.8);
}

.course-info-wrapper div:not(:last-child):after {
  content: " ";
  border-right: 1.63288px solid #f1f0f0;
  position: absolute;
  width: -webkit-fill-available;
  height: 50px;
  margin: 0 -50px;
}

.hr-break {
  height: 2px;
  border-width: 0;
  background-color: #ccc;
  margin-top: 50px;
}

.add_course_form_file_input .DropZone {
  position: absolute;
  width: 90%;
  opacity: 0;
}

.course-challenge-wrapper {
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 20px;
  padding: 10px 24px;
}

.course-challenge-name {
  font-weight: 700;
  font-size: 24px;
  letter-spacing: -0.8px;
  color: #000000;
}

.course-challenge-info {
  display: flex;
  align-items: center;
  margin: 12px 0;
}

.course-challenge-info-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  text-transform: capitalize;
}

.course-challenge-info-wrapper:not(:last-child)::after {
  content: "|";
  color: #676767;
  margin: 0 20px;
}

.course-challenge-info-wrapper img {
  height: 30px;
  width: 30px;
}

.course-challenge-info-wrapper span {
  font-weight: 400;
  font-size: 14.5px;
  line-height: 150%;
  color: #676767;
}

.course-challenge-description {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
}

.challenge-start-lab {
  background: var(--primary);
  border-radius: 12px;
  border: 1px solid transparent;
  width: 180px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  color: #ffffff;
}

.challenge-start-lab-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.lab-btn {
  margin-top: 30px;
}

.hr-break {
  height: 2px;
  border-width: 0;
  background-color: #ccc;
  margin-top: 50px;
}

/* Lab Practis */
.pack-img {
  border-radius: 8px;
  width: 70px;
  height: 70px;
}

.pack-img-sm {
  border-radius: 8px;
  width: 35px;
  height: 35px;
}

.pack-lab-desc {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #8392ab;
}

.pack-lab-fees,
.pack-lab-name {
  font-weight: 600;
  font-size: 16px;
  line-height: 162.5%;
  color: #172b4d;
  margin: 0;
}

.pack-lab-stats {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.pack-lab-stats span {
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: #636363;
}

.pack-lab-complete-wrapper {
  display: block;
  position: absolute;
  top: 13px;
  right: 16px;
  height: 40px;
  width: 40px;
}

.lab-loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*  */
.difficulty-easy {
  /* background-image: linear-gradient(to bottom right, #c8e6c9, #81c784); */
  background: linear-gradient(to right, #b4e0b4, #7dc47d);
}

.difficulty-medium {
  /* background-image: linear-gradient(to bottom right, #ffd54f, #ffb74d); */
  background: linear-gradient(to bottom, #f2b179, #f08239);
}

.difficulty-hard {
  /* background-image: linear-gradient(to bottom right, #ef9a9a, #e57373); */
  /* background: linear-gradient(to bottom, #e57373, #bf4040); */
  background-image: linear-gradient(to top right, #e57373, #ef5350);
}

/* Home page */
.home-course-card {
  margin: 0 6px !important;
  padding: 12px !important;
  background: transparent !important;
}

.home-course-img {
  height: 180px;
  width: 100%;
}

.home-course-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 21px;
  max-height: 45px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.react-pdf__Page {
  display: flex;
  justify-content: center;
}

.react-pdf__Document div,
.react-pdf__Document p {
  display: flex;
  text-align: center;
  justify-content: center;
}

/*  */
.pr-btn-with-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px 10px 12px;
  gap: 8px;
  background: #0a84ff;
  border: 1px solid #0a84ff;
  border-radius: 4px;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  color: #ffffff;
}

.pr-btn-with-icon:hover {
  color: #ffffff;
}

/*  */
.org_form_label {
  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  color: #070707;
  text-transform: capitalize;
}

.org_form_sublabel {
  font-weight: 400;
  font-size: 14px;
  line-height: 155%;
  color: #8f8f92;
}

.org_form_input {
  background: #ffffff;
  border-radius: 4px;

  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  color: #070707;
}

.question-img {
  width: 70px;
  height: 70px;
  object-fit: contain;
  border-radius: 10px;
}

.que-file-input {
  padding: 4px 8px;
  border-radius: 3px;
  width: 100%;
  cursor: pointer !important;
  border: 1px dashed var(--primary);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.que-file-input>[type='file'] {
  position: absolute;
  opacity: 0;
  width: 100%;
  z-index: 10;
  cursor: pointer;
}

.add_new_input {
  background: #ffffff;
  border: 1px dashed #d5d8e8;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 12px 0;
}

.add_new_input svg {
  cursor: pointer;
}

.add_new_title {
  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  color: #111111;
  cursor: pointer;
}

/* Quill */
.ql-container {
  height: 210px !important;
}

/* Table */
.table-action-other-btn,
.table-action-edit-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  gap: 8px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #d5d8e8;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 155%;
  color: #111111;
}

.table-action-delete-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  gap: 8px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #ef466f;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 155%;
  color: #ef466f;
}

/*  */
.single-course-about-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 155%;
  color: #070707;
}

/*  */
.org_page_title {
  font-weight: 500;
  font-size: 32px;
  line-height: 125%;
  color: #070707;
}

.course_daft_badge {
  padding: 8px 16px;
  width: 72px;
  height: 41px;
  background: #f2f4ff;
  border-radius: 4px;

  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  color: #111111;
}

.cancel_form_btn {
  padding: 10px 16px;
  height: 45px;
  background: #ffffff;
  border: 1px solid #ef466f;
  border-radius: 4px;

  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  color: #ef466f;
}

.draft_form_btn {
  padding: 10px 16px;
  height: 45px;
  background: #ffffff;
  border: 1px solid #d5d8e8;
  border-radius: 4px;

  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  color: #111111;
}

.publish_form_btn {
  padding: 10px 16px;
  height: 45px;
  background: #0a84ff;
  border: 1px solid #0a84ff;
  border-radius: 4px;

  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  color: #ffffff;
}

/*  */
.user_licence.select2-selection {
  height: 40px;
}

.complete {
  border: 1px solid #76cb94;
  background-color: #76cb94;
  border-radius: 50%;
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.unComplete {
  border: 1px solid #b0b0b0;
  background-color: #b0b0b0;
  border-radius: 50%;
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.complete-quiz {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.lab-complete {
  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  color: #070707;
}

.finish-exam-title {
  font-weight: 500;
  font-size: 32px;
  line-height: 125%;
  color: #070707;
  text-align: center;
}

.finish-exam-desc {
  font-weight: 400;
  font-size: 16px;
  line-height: 155%;
  color: #8f8f92;
  text-align: center;
}

.alert-with-icon {
  display: flex;
  align-items: baseline;
  gap: 10px;
}

.alert-dismissible .close {
  top: 15%;
  right: 2%;
}

.alert-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 155%;
}

.test_name {
  cursor: pointer;
}

/* AM/PM */
input[type="time"]::-webkit-datetime-edit-ampm-field {
  display: none;
}

/*  */
.auth-page-wrapper {
  min-height: 90vh;
}

.auth-page-sub-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 370px;
}

.card-border {
  border-radius: var(--bs-card-border-radius) !important;
}

.org-logo {
  height: 35px;
}

/*  */
.login-wrapper {
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 370px;
}

.btn-default {
  color: #fff;
  background-color: #172b4d;
  border-color: #172b4d;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.complexity-bg-badge-success {
  background: #e5f8ec;
  color: #41b66a;
}

.pr-btn-with-icon:hover {
  color: #ffffff;
}

.conformation-modal {
  z-index: 9999;
}

input[type=radio].toggle {
  display: none;
}

input[type=radio].toggle+label {
  cursor: pointer;
  min-width: 60px;
}

input[type=radio].toggle+label:hover {
  /* background: none; */
  color: #1a1a1a;
}

input[type=radio].toggle+label:after {
  background: var(--primary);
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  transition: left 200ms cubic-bezier(0.77, 0, 0.175, 1);
  width: 100%;
  z-index: -1;
  color: #fff
}

input[type=radio].toggle.toggle-left+label {
  border-right: 0;
}

input[type=radio].toggle.toggle-left+label:after {
  left: 100%;
}

input[type=radio].toggle.toggle-right+label {
  margin-left: -5px;
}

input[type=radio].toggle.toggle-right+label:after {
  left: -100%;
}

input[type=radio].toggle:checked+label {
  cursor: default;
  color: #fff;
  transition: color 200ms;
}

input[type=radio].toggle:checked+label:after {
  left: 0;
}

.expire-exam,
.error_badge {
  color: #d70000 !important;
  background: #FFECED !important;
}

.coming-exam,
.success_badge {
  color: #87CF63 !important;
  background: #F0F9EB !important;
}

.draft_badge {
  padding: 8px 16px;
  height: 41px;
  background: #f2f4ff;
  border-radius: 4px;

  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  color: #111111;
}

.pr-btn-with-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px 10px 12px;
  gap: 8px;
  background: #0a84ff;
  border: 1px solid #0a84ff;
  border-radius: 4px;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  color: #ffffff;
  cursor: pointer;
}

.pr-cancel-btn-with-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px 10px 12px;
  gap: 8px;
  background: var(--white);
  border: 1px solid #0a84ff;
  border-radius: 4px;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  color: var(--primary);
  cursor: pointer;
}

.warning-img {
  height: 120px;
  width: 120px;
}

.complexity-bg-badge-warning {
  background: #fff6e0;
  color: #ffb443;
}

.complexity-bg-badge-danger {
  background: #ffecf0;
  color: #ef466f;
}

.common-primary-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  background: #0a84ff;
  border: 1px solid #0a84ff;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  color: #ffffff;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-quiz-type-browse {
  width: 50%;
  text-align: center;
}

.quiz-add-type {
  padding: 2px 16px;
  border: 1px solid #d5d8e8;
  border-radius: 4px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quiz-add-type span {
  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  color: #070707;
}

.form-quiz-type {
  display: flex;
  align-items: center;
  padding: 4px;
  gap: 4px;
  width: 100%;
  height: 49px;
  background: #fff;
  cursor: pointer;
  border: 1px dashed #d5d8e8;
}

.form-quiz-type-browse {
  width: 50%;
  text-align: center;
}

@media (max-width: 1399px) {
  .org-profile-logo {
    width: 250px;
    height: 250px;
  }

  .course-info-wrapper div:not(:last-child):after {
    content: " ";
    margin: 0 -20px;
  }
}

@media (max-width: 1200px) {
  .is-sticky {
    position: relative;
    right: 0;
    top: 0;
    padding: 0;
  }
}

@media (max-width: 1299px) {
  .org-profile-logo {
    width: 200px;
    height: 200px;
  }
}

@media (max-width: 1192px) {
  .course-search-type {
    min-width: 200px !important;
  }

  .course-search-input {
    min-width: 300px !important;
  }

  .course-table-view-wrapper {
    overflow-x: auto;
  }
}

@media (min-width: 768px) and (max-width: 920px) {
  .states-card {
    width: 48%;
  }
}

@media (max-width: 475px) {
  .login-wrapper {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .states-card {
    width: 100%;
  }

  .org_page_title {
    font-size: 24px;
    line-height: 125%;
  }

  .course-search-type {
    min-width: 100px !important;
  }

  .course-search-input {
    min-width: 100px !important;
  }

  .org-profile-logo {
    width: 28 0px;
    height: 28 0px;
  }

  .course-info-wrapper {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .course-info-wrapper div:not(:last-child):after {
    content: " ";
    display: none;
  }

  .course-challenge-wrapper {
    padding: 10px;
  }

  .course-challenge-name {
    font-size: 18px;
  }

  .course-challenge-info-wrapper:not(:last-child)::after {
    content: "|";
    color: #676767;
    margin: 0 8px;
  }

  .course-challenge-info-wrapper span {
    font-size: 12px;
  }

  .challenge-start-lab {
    width: 100%;
  }
}

.MuiPaper-elevation2 {
  box-shadow: none !important;
}

.MuiTableCell-head {
  background: white !important;
}

.tab-access-tbl {
  width: 350px;
}

.tab-access-tbl thead tr {
  background: #e0e0e0;
}

.tab-access-tbl thead tr th {
  padding: 1rem;
  color: rgba(0, 0, 0, 0.8);
}

.tab-access-tbl tbody tr td {
  padding: 0.5rem;
  color: rgba(0, 0, 0, 0.8);
  border-bottom: 1px solid rgba(0, 0, 0, 0.4)
}
/*  */

.CircularProgressbar-text {
  fontSize: 20px;
  dominant-baseline: middle;
  text-anchor: middle;
}
.no-spinner::-webkit-outer-spin-button,
.no-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spinner {
  -moz-appearance: textfield;
}